import React, { useContext } from 'react';
import { Menu, Icon } from 'antd';
import './adminMenu.css';
import { navigationTo } from '../../../domain/middleware';
import { MenuContext } from '../context';
import { getState } from '../../../domain/store';

/* eslint-disable react/no-unescaped-entities */
export default function AdminMenu(): JSX.Element {
  const [visible, setVisible] = useContext(MenuContext); // eslint-disable-line @typescript-eslint/no-unused-vars
  const currentState = getState();
  const isAdminMCDPUser = currentState.loginInfo.managerFlag === '6';

  function goToAdminPage(path: string): void {
    currentState.searchCompanyList.isGetSearchResult = false;
    currentState.searchCompanyList.companyList = [];
    setVisible(false);
    navigationTo(path);
  }

  return (
    <Menu mode="inline">
      <Menu.Item key="search">
        <Icon type="search" />
        <span onClick={() => goToAdminPage('adminSearch')}>企業検索</span>
      </Menu.Item>
      {isAdminMCDPUser && (
        <Menu.Item key="userContractUpload">
          <Icon type="upload" />
          <span onClick={() => goToAdminPage('userContractUpload')}>請求データ アップロード</span>
        </Menu.Item>
      )}
      <Menu.Item key="adminIdInvoiceDownload">
        <Icon type="download" />
        <span onClick={() => goToAdminPage('adminIdInvoiceDownload')}>ID追加 請求書ダウンロード</span>
      </Menu.Item>
      {isAdminMCDPUser && (
        <Menu.Item key="userPaymentUpload">
          <Icon type="upload" />
          <span onClick={() => goToAdminPage('userPaymentUpload')}>入金データ アップロード</span>
        </Menu.Item>
      )}
      <Menu.Item key="userContractStatus">
        <Icon type="bars" />
        <span onClick={() => goToAdminPage('userContractStatus')}>請求ステータス管理画面</span>
      </Menu.Item>
      <Menu.Item key="userPaymentStatus">
        <Icon type="bars" />
        <span onClick={() => goToAdminPage('userPaymentStatus')}>入金ステータス管理画面</span>
      </Menu.Item>
      {isAdminMCDPUser && (
        <Menu.Item key="adminJournalCreation">
          <Icon type="bars" />
          <span onClick={() => goToAdminPage('adminJournalCreation')}>仕訳実行画面</span>
        </Menu.Item>
      )}
      {isAdminMCDPUser && (
        <Menu.Item key="adminJournalDataStatus">
          <Icon type="download" />
          <span onClick={() => goToAdminPage('adminJournalDataStatus')}>仕訳実行ステータス管理画面</span>
        </Menu.Item>
      )}
      <Menu.Item key="adminPaymentOkDownload">
        <Icon type="download" />
        <span onClick={() => goToAdminPage('adminPaymentOkDownload')}>入金OKファイルダウンロード</span>
      </Menu.Item>
      {isAdminMCDPUser && (
        <Menu.Item key="adminOverageBillingUsageCreation">
          <Icon type="bars" />
          <span onClick={() => goToAdminPage('adminOverageBillingUsageCreation')}>超過集計実行画面</span>
        </Menu.Item>
      )}
      {isAdminMCDPUser && (
        <Menu.Item key="adminOverageBillingStatus">
          <Icon type="bars" />
          <span onClick={() => goToAdminPage('adminOverageBillingStatus')}>超過請求ステータス管理画面</span>
        </Menu.Item>
      )}
    </Menu>
  );
}
