import React from 'react';
import { Table, Button, Modal, Popover } from 'antd';
import './invoiceList.css';
import { getState } from '../../../domain/store';
import { IProps, IInvoiceList as IInvoice } from '../../../domain/model';
import { jpyFormater, updateIndex, yyyymmddSlashFormatter, isOwnerOverageInvoice } from '../../../domain/common';
import {
  onGetInvoicePDF,
  onCancelOwnerUnapaidInvoice,
  onError,
  onPageChangeInvoiceList,
  onGetToken,
  onClearToken
} from '../../../domain/middleware';
import * as paginationUtil from '../../../domain/utils/paginationUtil';
import moment from 'moment';

/* eslint-disable react/no-unescaped-entities */
export default function IInvoiceList({ state }: IProps): JSX.Element {
  const cancelDisabled = state.isAdmin && state.loginInfo.managerFlag !== '6';
  type invoiceType = 'unpaidInvoice' | 'completedInvoice';
  const getColumns = (invoiceType: invoiceType): any[] => {
    const columns = [
      {
        key: `invoiceNumber${updateIndex()}`,
        title: '請求書番号',
        width: '150px',
        dataIndex: 'invoiceNumber'
      },
      {
        key: `invoiceCreatedDate${updateIndex()}`,
        title: '申し込み日',
        width: '150px',
        dataIndex: 'invoiceCreatedDate'
      },
      {
        key: `dueDate${updateIndex()}`,
        title: '支払期限',
        width: '150px',
        dataIndex: 'dueDate'
      },
      {
        key: `amount${updateIndex()}`,
        title: '請求金額',
        dataIndex: 'amount',
        width: '150px',
        className: 'right'
      },
      {
        key: `customerRemark${updateIndex()}`,
        title: 'お客様用備考',
        dataIndex: 'customerRemark',
        className: 'left customerRemark'
      }
    ];
    if (invoiceType === 'unpaidInvoice') {
      columns.push({
        key: `pdf${updateIndex()}`,
        title: '見積書・請求書',
        width: '150px',
        dataIndex: 'pdf'
      });
      columns.push({
        key: `cancel${updateIndex()}`,
        title: 'キャンセル',
        dataIndex: 'cancel',
        width: '150px',
        className: 'center'
      });
    } else {
      columns.push({
        key: `pdf${updateIndex()}`,
        title: '請求書',
        width: '300px',
        dataIndex: 'pdf'
      });
    }
    return columns;
  };

  interface IDataSource {
    invoiceId: any;
    invoiceNumber: string;
    invoiceDate: string;
    amount: string;
  }

  const cancelInvoiceColumns = [
    {
      title: '請求書番号',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      className: 'center'
    },
    {
      title: '申し込み日',
      dataIndex: 'invoiceDate',
      key: 'createdDate',
      className: 'center'
    },
    {
      title: '請求金額',
      dataIndex: 'amount',
      key: 'amount',
      className: 'right'
    }
  ];

  let confirmDataSource: IDataSource[] = [];
  async function confirmMordal(invoiceId: string, invoiceNumber: string): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    function confirmModalContent(): any {
      return (
        <>
          <Table
            className={'confirmInvoiceDeleteTable'}
            columns={cancelInvoiceColumns}
            dataSource={confirmDataSource}
            bordered
            pagination={false}
          />
        </>
      );
    }
    const onPostDeleteUnpaidInvoiceAsync = async (): Promise<void> => {
      try {
        await onCancelOwnerUnapaidInvoice(invoiceId, invoiceNumber);
      } catch (err) {
        onError(err);
      }
    };

    Modal.confirm({
      title: '請求書のキャンセル処理を実行します。',
      centered: true,
      content: confirmModalContent(),
      width: 600,
      okText: 'キャンセルを確定',
      cancelText: '取消',
      onOk: onPostDeleteUnpaidInvoiceAsync,
      onCancel: onClearToken
    });
  }

  function cancelHandleOk(invoiceId: string): void {
    confirmDataSource = [];
    const targetInvoice = state.unpaidInvoiceList.find(ui => ui.invoiceId === invoiceId);
    if (targetInvoice) {
      confirmDataSource.push({
        invoiceId: targetInvoice.invoiceId,
        invoiceNumber: targetInvoice.invoiceNumber,
        invoiceDate: yyyymmddSlashFormatter(targetInvoice.createdDate),
        amount: jpyFormater(targetInvoice.amount)
      });

      confirmMordal(invoiceId, targetInvoice.invoiceNumber);
    }
  }

  function buttonToCancel(invoice: IInvoice): any {
    let disabled: boolean = cancelDisabled;
    if (!cancelDisabled && isOwnerOverageInvoice(invoice)) {
      // システム運用担当者でも超過請求の場合は非活性にする
      disabled = true;
    }

    return (
      <Button disabled={disabled} onClick={() => cancelHandleOk(invoice.invoiceId)}>
        申込キャンセル
      </Button>
    );
  }

  /**
   * 請求書の申し込み日を画面表示用に編集する
   * @param invoice 請求書
   * @returns 編集した申し込み日
   */
  function editInvoiceCreatedDate(invoice: IInvoice): string {
    let invoiceCreatedDate: string;

    if (isOwnerOverageInvoice(invoice)) {
      // 超過請求の場合
      invoiceCreatedDate = '-';
    } else {
      // 上記以外の場合
      invoiceCreatedDate = yyyymmddSlashFormatter(invoice.createdDate);
    }

    return invoiceCreatedDate;
  }

  /**
   * 請求書のお客様用備考を画面表示用に編集する
   * @param invoice 請求書
   * @returns 編集したお客様用備考
   */
  function editCustomerRemark(invoice: IInvoice): JSX.Element {
    let customerRemark: string;

    if (isOwnerOverageInvoice(invoice)) {
      // 超過請求の場合
      customerRemark = moment(invoice.targetDate)
        .add(-1, 'month')
        .format('YYYY年MM月超過利用分');
    } else {
      // 上記以外の場合
      customerRemark = invoice.customerRemark;
    }

    return (
      <div className="customerRemark">
        <Popover
          placement="topLeft"
          content={
            <div>
              <pre className="popoverPre invoiceListPopover">{customerRemark}</pre>
            </div>
          }
        >
          <span>{customerRemark}</span>
        </Popover>
      </div>
    );
  }

  const unpaidInvoiceList: any = [];
  state.unpaidInvoiceList.forEach(obj => {
    unpaidInvoiceList.push({
      key: `invoiceId${updateIndex()}`,
      invoiceNumber: obj.invoiceNumber,
      invoiceCreatedDate: editInvoiceCreatedDate(obj),
      dueDate: yyyymmddSlashFormatter(obj.dueDate),
      amount: jpyFormater(obj.amount),
      customerRemark: editCustomerRemark(obj),
      pdf: (
        <a href="#!" onClick={() => onGetInvoicePDF(obj.invoiceId, obj.invoiceNumber)} className="pdfIcon">
          <img src="../PDF_32.png" alt="PDF"></img>
        </a>
      ),
      cancel: buttonToCancel(obj)
    });
  });

  const completedInvoiceList: any = [];
  state.completedInvoiceList.forEach(obj => {
    completedInvoiceList.push({
      key: `invoiceId2${updateIndex()}`,
      invoiceNumber: obj.invoiceNumber,
      invoiceCreatedDate: editInvoiceCreatedDate(obj),
      dueDate: yyyymmddSlashFormatter(obj.dueDate),
      amount: jpyFormater(obj.amount),
      customerRemark: editCustomerRemark(obj),
      pdf: (
        <a href="#!" onClick={() => onGetInvoicePDF(obj.invoiceId, obj.invoiceNumber)} className="pdfIcon">
          <img src="../PDF_32.png" alt="PDF"></img>
        </a>
      )
    });
  });

  function checkElementHidden(data: any[]): any {
    const style = {} as React.CSSProperties;

    if (data.length === 0) {
      // hiddenで非表示
      style.display = 'none';
    }
    return style;
  }

  const currentPage = paginationUtil.getPageNo(getState().invoiceListPagination.offset);
  const totalRows = getState().invoiceListPagination.totalCount;
  function onPageChange(page: number): void {
    onPageChangeInvoiceList(paginationUtil.getOffset(page));
  }

  return (
    <div className="invoiceList">
      <h2>請求一覧</h2>
      {state.unpaidInvoiceList.length > 0 || state.completedInvoiceList.length > 0 ? (
        <>
          <div className="oneContent" style={checkElementHidden(unpaidInvoiceList)}>
            <h3>
              <span id="invoiceList">未払請求</span>
            </h3>
            <Table className="invoiceListTable" columns={getColumns('unpaidInvoice')} dataSource={unpaidInvoiceList} pagination={false} />
          </div>

          <div className="oneContent" style={checkElementHidden(completedInvoiceList)}>
            <h3>支払済請求</h3>
            <Table
              className="invoiceListTable"
              columns={getColumns('completedInvoice')}
              dataSource={completedInvoiceList}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: currentPage,
                total: totalRows,
                onChange: onPageChange
              }}
            />
          </div>
        </>
      ) : (
        <h3>請求がありません。</h3>
      )}
    </div>
  );
}
