import React from 'react';
import { IProps } from '../../domain/model';
import { OverageBillingJobErrorDetails } from '../../components/admin/overageBilling/status/overageBillingJobErrorDetails';

export default function OverageBillingStatusDetailPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <OverageBillingJobErrorDetails state={state} />
        </>
      )}
    </>
  );
}
