import React, { useState } from 'react';
import './header.css';
import { IProps } from '../../../domain/model';
import AdminBadge from '../../admin/adminBadge/adminBadge';
import AdminMenu from '../../admin/adminMenu/adminMenu';
import { Drawer, Icon } from 'antd';
import { MenuContext } from '../../admin/context';
import { onLogout } from 'domain/middleware';

/* eslint-disable react/no-unescaped-entities */
export default function Header({ state }: IProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  function checkMenuHidden(): any {
    const style = {
      visibility: 'hidden'
    } as React.CSSProperties;

    if (state.isAdmin) {
      style.visibility = 'visible';
    }
    return style;
  }

  function renderContents(): any {
    let tempValue: any = '';
    if (state.pageTitle) {
      tempValue = (
        <>
          <header>
            <div className="imgLogo">
              <img src="../../header_logo_keiyaku.png" alt="契約管理"></img>
            </div>
            <h1>{state.pageTitle}</h1>

            <div className="menuContents" style={checkMenuHidden()}>
              <span onClick={showDrawer}>
                <Icon type="menu" />
              </span>

              <Drawer title="管理者メニュー" placement="left" closable={true} onClose={onClose} visible={visible} width="350px">
                <AdminMenu />
              </Drawer>
            </div>
            <div className="rightContentsgroup">
              <AdminBadge isAdmin={state.isAdmin} />
              <div className="userInfo">
                <div>{state.accountInfo.account}</div>
                <div>
                  {state.loginInfo.lastName}
                  <span>&nbsp;</span>
                  {state.loginInfo.firstName}
                  <span>さん</span>
                </div>
              </div>
            </div>
          </header>

          <div className="mainNavBar">
            <div className="mainNavBarRightMenu">
              {state.pageTitle !== '不要請求書削除' && (
                <a
                  className="agreementLink"
                  href="https://gs.kensetsu-site.com/helpdesk/public_file/owner_yakkan.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  「建設サイト・シリーズ」オーナー利用約款はこちら
                </a>
              )}
              {state.pageTitle !== '不要請求書削除' ? (
                // eslint-disable-next-line
                <a
                  className="manualLink"
                  href="https://manual.kensetsu-site.com/gs/manual/manual-273/manual-9029"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type="read" /> 使い方
                </a>
              ) : (
                // eslint-disable-next-line
                <a
                  className="manualLink"
                  href="https://manual.kensetsu-site.com/gs/manual/manual-273/manual-9469"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type="read" /> 使い方
                </a>
              )}
              {
                // eslint-disable-next-line
                <a
                  className="closeLink"
                  onClick={e => {
                    onLogout();
                  }}
                >
                  <Icon type="close" /> 終了(閉じる)
                </a>
              }
            </div>
          </div>
        </>
      );
    }
    return tempValue;
  }

  return (
    <MenuContext.Provider value={[visible, setVisible]}>
      <span>{renderContents()}</span>
    </MenuContext.Provider>
  );
}
