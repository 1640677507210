import { Button, DatePicker, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';
import 'moment/locale/ja';
import React from 'react';
import { onExecuteOverageBillingUsageCreation } from '../../../../domain/middleware';
import { IProps } from '../../../../domain/model';
import './overageBillingUsageCreation.css';

/**
 * 超過集計実行画面コンポーネント
 * @param state State
 * @returns コンポーネント
 */
export const OverageBillingUsageCreation = ({ state }: IProps): JSX.Element => {
  const { MonthPicker } = DatePicker;
  // カレンダーの日本語化が効くようYYYY-MM-DD形式に一度変換
  const targetDate = moment(state.overageBillingUsageCreation.params.targetDate.format('YYYY-MM-DD'));

  return (
    <>
      <Form className="search" layout="inline">
        <div>
          <Form.Item label="対象日付">
            <MonthPicker
              id="targetDate"
              locale={locale}
              format="YYYY年MM月度"
              disabled={true}
              defaultValue={targetDate}
              value={targetDate}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button className="executeBtn" onClick={async () => await onExecuteOverageBillingUsageCreation()}>
              超過集計実行
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
