import { Button, DatePicker, Icon } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { IProps } from 'domain/model';
import moment, { Moment } from 'moment';
import 'moment/locale/ja';
import React, { useState } from 'react';
import { onChangeUsageDetailsMonth, onDownloadUsageDetailsFile } from '../../../domain/middleware';
import './usageDetails.css';

export default function UsageDetails({ state }: IProps): JSX.Element {
  const [disableDownload, setDisableDownload] = useState(false);

  // カレンダーの日本語化が効くようYYYY-MM-DD形式に一度変換
  state.usageDetails.params.targetDate = moment(state.usageDetails.params.targetDate.format('YYYY-MM-DD'));

  /**
   * 対象年月カレンダーの非活性制御
   * @param current 対象年月カレンダーで選択された年月
   * @returns true：非活性、false：活性
   */
  function disabledDate(current: Moment | null): boolean {
    if (!current) {
      return false;
    }
    // 2年前
    const from = moment()
      .subtract(2, 'years')
      .subtract(1, 'months');
    // 前月
    const to = moment().subtract(1, 'months');

    // 2年前～前月は活性、左記以外は非活性
    return current < from || to < current;
  }

  return (
    <div className="usageDetails">
      <h2>利用実績</h2>
      <DatePicker.MonthPicker
        id="usageDetailsMonthPicker"
        locale={locale}
        onChange={(date: Moment | null) => onChangeUsageDetailsMonth(date, setDisableDownload)}
        placeholder={'対象年月を選択'}
        disabledDate={disabledDate}
        allowClear={false}
        format="YYYY年MM月分"
        defaultValue={state.usageDetails.params.targetDate}
        value={state.usageDetails.params.targetDate}
      />
      <Button
        type="primary"
        id="downloadUsageDetailsBtn"
        className="downloadUsageDetailsBtn"
        onClick={() => onDownloadUsageDetailsFile(state.usageDetails.params.targetDate)}
        disabled={disableDownload}
      >
        ダウンロード
        <Icon style={{ fontSize: '18px' }} type="download" />
      </Button>
    </div>
  );
}
