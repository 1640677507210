import axios from 'axios';

// 起動環境によってAPIのエンドポイント切り替える
const baseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_INVOICE_BACKEND_API_KEY;

export default axios.create({
  withCredentials: true,
  headers: {
    'x-api-key': apiKey,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache'
  },
  baseURL
});
