import React from 'react';
import { Form, Button, Icon, DatePicker, Table, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { IProps, IOverageBillingJob } from '../../../../domain/model';
import {
  onSetOverageBillingJobStatusParams,
  onSearchOverageBillingJobDetailList,
  onSearchOverageBillingJobStatus,
  onDownloadOverageBillingUsageSummaryFile
} from '../../../../domain/middleware';
import './overageBillingJobStatus.css';
import 'moment/locale/ja';
import * as common from 'domain/common';
import moment from 'moment';

export const OverageBillingJobStatus = ({ state }: IProps): JSX.Element => {
  const { MonthPicker } = DatePicker;

  /**
   * 明細データソースIF
   */
  interface IDataSource {
    jobNumber: number;
    targetDate: string;
    processName: JSX.Element;
    status: string;
    errorMessage: JSX.Element;
    successCountStandard: string;
    errorCountStandard: string;
    successCountIndividual: string;
    errorCountIndividual: string;
    insertDatetime: string;
    updateDatetime: string;
    detailButton: JSX.Element;
  }

  /** 列定義 */
  const COLUMNS: ColumnProps<IDataSource>[] = [
    { title: 'No', dataIndex: 'jobNumber', className: 'jobNumber right' },
    { title: '対象年月', dataIndex: 'targetDate', className: 'targetDate center' },
    { title: '処理', dataIndex: 'processName', className: 'processName' },
    { title: 'ステータス', dataIndex: 'status', className: 'status center' },
    { title: 'エラーメッセージ', dataIndex: 'errorMessage', className: 'errorMessage' },
    { title: '成功件数\n（標準）', dataIndex: 'successCountStandard', className: 'successCountStandard count right' },
    { title: 'エラー件数\n（標準）', dataIndex: 'errorCountStandard', className: 'errorCountStandard count right' },
    { title: '成功件数\n（個別）', dataIndex: 'successCountIndividual', className: 'successCountIndividual count right' },
    { title: 'エラー件数\n（個別）', dataIndex: 'errorCountIndividual', className: 'errorCountIndividual count right' },
    { title: '登録日時', dataIndex: 'insertDatetime', className: 'insertDatetime datetimeColumn right' },
    { title: '更新日時', dataIndex: 'updateDatetime', className: 'updateDatetime datetimeColumn right' },
    { title: '明細', dataIndex: 'detailButton', className: 'detailButtonColumn center' }
  ];

  /** 各種日時フォーマット */
  const DATETIME_FORMAT = 'YYYY/MM/DD[\n]HH:mm:ss';

  /**
   * 画面表示用の件数の編集
   * @param count 件数
   * @returns 画面表示用の件数
   */
  const editCount = (count: number | null): string => {
    return count != null ? common.numberFormater(count) : '-';
  };

  /**
   * 明細有無の判定
   * @param job バッチ実行ステータス
   * @returns true：明細あり、false：明細なし
   */
  const hasDetail = (job: IOverageBillingJob): boolean => {
    return (
      (job.successCountStandard != null && job.errorCountStandard != null && job.errorCountStandard > 0) ||
      (job.successCountIndividual != null && job.errorCountIndividual != null && job.errorCountIndividual > 0)
    );
  };

  /**
   * 明細ボタンの生成
   * @param job バッチ実行ステータス
   * @returns 明細ボタン
   */
  const createDetailButton = (job: IOverageBillingJob): JSX.Element => {
    return (
      <Button onClick={() => onSearchOverageBillingJobDetailList(job.jobNumber)} disabled={!hasDetail(job)}>
        明細
      </Button>
    );
  };

  // カレンダーの日本語化が効くようYYYY-MM-DD形式に一度変換
  state.overageBillingJobStatus.params.targetDate = moment(state.overageBillingJobStatus.params.targetDate.format('YYYY-MM-DD'));

  // 明細にデータを追加
  const dataSource = state.overageBillingJobStatus.jobList.map(job => {
    return {
      jobNumber: job.jobNumber,
      targetDate: moment(job.targetDate).format('YYYY年MM月度'),
      processName: (
        <div className="processName">
          <Popover
            placement="topLeft"
            content={
              <div>
                <pre className="popoverPre processNamePopover">{job.processName}</pre>
              </div>
            }
          >
            <span>{job.processName}</span>
          </Popover>
        </div>
      ),
      status: job.statusName,
      errorMessage: (
        <div className="errorMessage">
          <Popover
            placement="topLeft"
            content={
              <div>
                <pre className="popoverPre errorMessagePopover">{job.errorMessage}</pre>
              </div>
            }
          >
            <span>{job.errorMessage}</span>
          </Popover>
        </div>
      ),
      successCountStandard: editCount(job.successCountStandard),
      errorCountStandard: editCount(job.errorCountStandard),
      successCountIndividual: editCount(job.successCountIndividual),
      errorCountIndividual: editCount(job.errorCountIndividual),
      insertDatetime: moment(job.insertDatetime).format(DATETIME_FORMAT),
      updateDatetime: moment(job.updateDatetime).format(DATETIME_FORMAT),
      detailButton: createDetailButton(job)
    };
  });

  // 超過請求ファイルダウンロードボタンの活性・非活性判定
  const isDownloadOverageBillingFileBtnEnabled: boolean = state.overageBillingJobStatus.jobList.some(job => {
    return job.processKey === 'CreateOverageBillingFileJob' && job.status === 'success';
  });

  return (
    <div className="overageBillingJobStatus">
      <div className="buttonArea">
        <Form layout="inline" onSubmit={onSearchOverageBillingJobStatus}>
          <Form.Item label="対象日付">
            <MonthPicker
              id="targetDate"
              locale={locale}
              onChange={(date, dateString) => {
                onSetOverageBillingJobStatusParams(date);
              }}
              placeholder={'対象日付を選択'}
              allowClear={false}
              format="YYYY年MM月度"
              defaultValue={state.overageBillingJobStatus.params.targetDate}
              value={state.overageBillingJobStatus.params.targetDate}
            />
          </Form.Item>
        </Form>
        <Form layout="inline">
          <Form.Item>
            <Button
              type="primary"
              id="downloadOverageBillingFileBtn"
              className="downloadOverageBillingFileBtn"
              onClick={e => onDownloadOverageBillingUsageSummaryFile(e)}
              disabled={!isDownloadOverageBillingFileBtnEnabled}
            >
              超過請求集計ファイルダウンロード
              <Icon style={{ fontSize: '18px' }} type="download" />
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table className={'commonTable'} columns={COLUMNS} dataSource={dataSource} rowKey="jobNumber" pagination={false} size={'small'} />
    </div>
  );
};
