import React from 'react';
import { IProps } from '../../domain/model';
import { OverageBillingUsageCreation } from '../../components/admin/overageBilling/usageCreation/overageBillingUsageCreation';

/**
 * 超過集計実行画面
 * @param state State
 * @returns 画面
 */
export default function OverageBillingUsageCreationPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <OverageBillingUsageCreation state={state} />
        </>
      )}
    </>
  );
}
