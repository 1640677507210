import React from 'react';
import { IProps } from '../../domain/model';
import { OverageBillingJobStatus } from '../../components/admin/overageBilling/status/overageBillingJobStatus';

export default function OverageBillingStatusPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <OverageBillingJobStatus state={state} />
        </>
      )}
    </>
  );
}
