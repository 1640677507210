import { Button, Popover, Table } from 'antd';
import moment from 'moment';
import 'moment/locale/ja';
import React from 'react';
import { navigationTo } from '../../../../domain/middleware';
import { IProps } from '../../../../domain/model';
import './overageBillingJobErrorDetails.css';
import { ColumnProps } from 'antd/lib/table';

export const OverageBillingJobErrorDetails = ({ state }: IProps): JSX.Element => {
  /**
   * 明細データソースIF
   */
  interface IDataSource {
    lineNo: number;
    companyId: string;
    companyName: JSX.Element;
    errorMessage: JSX.Element;
    insertDatetime: string;
    updateDatetime: string;
  }

  /** 列定義 */
  const COLUMNS: ColumnProps<IDataSource>[] = [
    { title: 'No', dataIndex: 'lineNo', key: 'lineNo', className: 'lineNo right' },
    { title: '企業ID', dataIndex: 'companyId', className: 'companyId' },
    { title: '企業名', dataIndex: 'companyName', className: 'companyName' },
    { title: 'エラーメッセージ', dataIndex: 'errorMessage', className: 'errorMessage' },
    { title: '登録日時', dataIndex: 'insertDatetime', className: 'insertDatetime center' },
    { title: '更新日時', dataIndex: 'updateDatetime', className: 'updateDatetime center' }
  ];

  /** 各種日時フォーマット */
  const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

  // 明細にデータを追加
  const dataSource: IDataSource[] = state.overageBillingJobStatus.errorList.map((detail, index) => {
    return {
      lineNo: index + 1,
      companyId: detail.companyId,
      companyName: (
        <div className="companyName">
          <Popover
            placement="topLeft"
            content={
              <div>
                <pre className="popoverPre companyNamePopover">{detail.companyName}</pre>
              </div>
            }
          >
            <span>{detail.companyName}</span>
          </Popover>
        </div>
      ),
      errorMessage: (
        <div className="errorMessage">
          <Popover
            placement="topLeft"
            content={
              <div>
                <pre className="popoverPre errorMessagePopover">{detail.errorMessage}</pre>
              </div>
            }
          >
            <span>{detail.errorMessage}</span>
          </Popover>
        </div>
      ),
      insertDatetime: moment(detail.insertDatetime).format(DATETIME_FORMAT),
      updateDatetime: moment(detail.updateDatetime).format(DATETIME_FORMAT)
    };
  });

  return (
    <div className="overageBillingJobErrorDetails">
      <div className="titleArea">
        <h3 title={state.overageBillingJobStatus.errorProcessName + '処理'}>{state.overageBillingJobStatus.errorProcessName}処理</h3>
      </div>
      <Table className={'commonTable'} columns={COLUMNS} dataSource={dataSource} rowKey="lineNo" pagination={false} size={'small'} />
      <Button className="backBtn" onClick={() => navigationTo('adminOverageBillingStatus')}>
        戻る
      </Button>
    </div>
  );
};
