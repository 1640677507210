import { Modal, message } from 'antd';
import moment, { Moment } from 'moment';
import { blobToText, downloadOverageBillingUsageSummaryFile, downloadUsageDetailsFile } from '../common';
import history from '../history';
import myHttpClient from '../httpClient';
import * as Model from '../model';
import { getState, setState } from '../store';
import { clearToken, getToken, isLoading } from 'domain/reducers';

/**
 * 契約トップ画面 利用実績 パラメーター設定
 * - 対象年月にnullを指定した場合、先月月初の日付を設定する
 * @param targetDate 対象年月
 */
export const setUsageDetailsParams = (targetDate: Moment | null): void => {
  const currentState = getState();
  if (targetDate) {
    currentState.usageDetails.params.targetDate = targetDate;
  } else {
    // 対象年月にnullを指定した場合

    // 先月月初の日付を設定する
    currentState.usageDetails.params.targetDate = moment()
      .add(-1, 'month')
      .startOf('month');
  }

  // Stateの更新
  setState((state: Model.IState) => ({
    ...state
  }));
};

/**
 * 利用実績ファイル 取得リデューサー
 * - 対象年月にnullを指定した場合、システムエラー画面に遷移する
 * @param targetDate 対象年月
 */
export const getUsageDetailsFile = async (targetDate: Moment | null): Promise<void> => {
  // URL
  const url = `/usage-details-file/${targetDate?.format('YYYY-MM-DD')}`;

  // 利用実績ファイルをbackendからダウンロード
  await myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.appStatus === 'error') {
            // 利用実績ファイルが見つからなかった場合
            message.error('対象の利用実績ファイルが存在しません。');
            return;
          }

          if (result.data.readStatus === 'success') {
            // backendからダウンロードできた場合

            // クライアントへダウンロードさせる
            downloadUsageDetailsFile(response);
          }
        })
        .catch(function(error) {
          // バックエンドとの通信でエラーになった場合
          // システムエラー画面に遷移
          history.push('/systemError');
        })
        .finally(() => {
          setState((state: Model.IState) => ({
            ...state,
            loading: false
          }));
        });
    });
};

/**
 * 超過請求ステータス管理画面 一覧取得
 */
export const searchAdminOverageBillingStatus = async (): Promise<void> => {
  const currentState = getState();
  const targetDate = currentState.overageBillingJobStatus.params.targetDate;

  // backend呼び出し
  myHttpClient
    .get(`/admin/overage-billing/status/${targetDate.format('YYYY-MM-DD')}`)
    .then((response: any) => {
      currentState.overageBillingJobStatus.jobList = response.statusList;
      currentState.overageBillingJobStatus.errorProcessName = '';
      currentState.overageBillingJobStatus.errorList = [];
      const loading = false;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    })
    .catch(e => {
      history.push('/systemError');
    });
};

/**
 * 超過請求ステータス管理画面 初期表示
 */
export const refreshAdminOverageBillingStatus = async (): Promise<void> => {
  const currentState = getState();
  currentState.pageTitle = '超過請求ステータス管理';

  await searchAdminOverageBillingStatus();
  history.push('/admin/overage-billing/status');
};

/**
 * 超過請求ステータス管理画面 検索パラメーター設定
 * - 対象日付にnullを指定した場合、先月月初の日付を設定する
 * @param targetDate 対象日付
 */
export function setOverageBillingJobStatusParams(targetDate: Moment | null): void {
  const currentState = getState();
  if (targetDate) {
    currentState.overageBillingJobStatus.params.targetDate = targetDate;
  } else {
    currentState.overageBillingJobStatus.params.targetDate = moment()
      .add(-1, 'month')
      .startOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 超過請求ステータス管理画面 超過請求集計ファイルダウンロード
 */
export const getOverageBillingUsageSummaryFile = async (): Promise<void> => {
  // 対象年月の取得
  const currentState = getState();
  const targetDate = currentState.overageBillingJobStatus.params.targetDate.format('YYYY-MM-DD');

  // URL
  const url = `/admin/overage-billing/usage-summary-file/${targetDate}`;

  // 超過請求集計ファイルをbackendからダウンロード
  await myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.appStatus === 'error') {
            // 超過請求集計ファイルが見つからなかった場合
            message.error('超過請求集計ファイルが見つかりません。');
            return;
          }

          if (result.data.readStatus === 'success') {
            // backendからダウンロードできた場合

            // クライアントへダウンロードさせる
            downloadOverageBillingUsageSummaryFile(response);
          }
        })
        .catch(function(error) {
          // バックエンドとの通信でエラーになった場合
          // システムエラー画面に遷移
          history.push('/systemError');
        });
    });
};

/**
 * 超過請求エラー明細画面 一覧取得
 * @param jobNumber ジョブNo
 */
export const searchAdminOverageBillingStatusDetail = async (jobNumber: number): Promise<void> => {
  const currentState = getState();

  // backend呼び出し
  myHttpClient
    .get(`/admin/overage-billing/status/details/${jobNumber}`)
    .then((response: any) => {
      currentState.overageBillingJobStatus.errorProcessName = response.processName;
      currentState.overageBillingJobStatus.errorList = response.detailList;
      setState((state: Model.IState) => ({
        ...state
      }));
    })
    .catch(e => {
      history.push('/systemError');
    });
};

/**
 *  超過請求エラー明細画面 初期表示
 * @param jobNumber ジョブNo
 */
export const refreshAdminOverageBillingStatusDetail = async (jobNumber: number): Promise<void> => {
  const currentState = getState();
  currentState.pageTitle = '超過請求エラー明細';

  await searchAdminOverageBillingStatusDetail(jobNumber);
  history.push(`/admin/overage-billing/status/details/${jobNumber}`);
};

/**
 * 超過集計実行画面 リフレッシュ
 */
export const refreshAdminOverageBillingUsageCreation = async (): Promise<void> => {
  try {
    const currentState = getState();
    currentState.pageTitle = '超過集計実行';

    // 超過集計実行画面に遷移
    history.push('/admin/overage-billing/usage/create');
  } finally {
    // ローディング中を解除
    isLoading(false);
  }
};

/**
 * 超過集計実行画面 確認メッセージ 「はい」ボタン押下処理
 */
export const approveOverageBillingUsageCreation = async (): Promise<void> => {
  // ローディング中を設定
  isLoading(true);

  const currentState = getState();

  // バックエンドを呼び出す
  await myHttpClient
    .post('/admin/overage-billing/usage/create', {
      targetDate: currentState.overageBillingUsageCreation.params.targetDate.format('YYYY-MM-DD')
    })
    .then((response: any) => {
      // 正常な場合
      if (response.message) {
        message.error(response.message, 3);
      } else {
        message.success('超過集計実行を開始しました。', 3);
      }
    })
    .catch((error: any) => {
      // エラーが発生した場合
      message.error('想定外のエラーが発生しました。', 3);

      // システムエラー画面に遷移
      history.push('/systemError');
    })
    .finally(() => {
      // ローディング中を解除
      isLoading(false);
    });
};

/**
 * 超過集計実行画面 確認メッセージ 「いいえ」ボタン押下処理
 */
export const cancelOverageBillingUsageCreation = (): void => {
  // トークン削除
  clearToken().catch((error: any) => {
    // ignore
  });
};

/**
 * 超過集計実行画面 超過集計実行
 */
export const executeOverageBillingUsageCreation = async (): Promise<void> => {
  // トークンの取得
  await getToken()
    .then(() => {
      // ローディング中を解除
      isLoading(false);

      // 確認メッセージの表示
      Modal.confirm({
        width: 600,
        centered: true,
        content: '超過集計を実行します。よろしいですか？',
        okText: 'はい',
        cancelText: 'いいえ',
        autoFocusButton: 'cancel',
        onOk: async (): Promise<void> => {
          // 処理中にCancelボタンが押されないよう、ダイアログをすぐ閉じる
          /* istanbul ignore next */
          setTimeout(() => {
            approveOverageBillingUsageCreation();
          }, 0);
        },
        onCancel: () => {
          /* istanbul ignore next */
          cancelOverageBillingUsageCreation();
        }
      });
    })
    .catch((error: any) => {
      // エラーが発生した場合
      message.error('超過集計実行に失敗しました。', 3);
    })
    .finally(() => {
      // ローディング中を解除
      isLoading(false);
    });
};
